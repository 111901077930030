import { BaseRequest } from 'Services/base';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { PermissionType } from 'Services/permissions/interfaces';
import { Base64 } from 'js-base64';
import { IAppUserMeta } from 'App/types';
import { JsonObject, JsonValue } from '@cover42/protobuf-util';

dayjs.extend( utc );

export class AuthRequest extends BaseRequest {
  constructor(
    credentials: Credentials,
  ) {
    super( {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      url: '/authservice/v1/login',
      data: credentials,
      withCredentials: true,
      responseType: 'json',
      loginRequest: true,
    }, false );
  }
}

export type AuthResponseErrorMessageType = 'INVALID_CREDENTIALS' | 'PASSWORD_FAILED_POLICY_CHECK' | 'UNAUTHORIZED';

export interface AuthResponseError {
  statusCode: number;
  message: AuthResponseErrorMessageType;
}

export class RefreshTokenRequest extends BaseRequest {
  constructor(
    userName: string,
  ) {
    super( {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      url: '/authservice/v1/refresh-token',
      data: { username: userName },
      withCredentials: true,
      responseType: 'json',
      loginRequest: true,
    }, false );
  }
}

export class LogoutRequest extends BaseRequest {
  constructor(
    userName: string,
  ) {
    super( {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      url: '/authservice/v1/logout',
      data: { username: userName },
      withCredentials: true,
      responseType: 'json',
    }, false );
  }
}

export class TenantRequest extends BaseRequest {
  constructor(
    tenantId: number,
  ) {
    super( {
      method: 'GET',
      url: `/tenantservice/v1/tenants/${tenantId}`,
      responseType: 'json',
    } );
  }
}

export class VerifyInviteRequest extends BaseRequest<VerifyInviteResponse> {
  constructor(
    token: string,
  ) {
    super( {
      method: 'GET',
      url: `/authservice/v1/verify-invite?token=${token}`,
      responseType: 'json',
      loginRequest: false,
    }, false );
  }
}

export class CreateUserRequest extends BaseRequest<IUser> {
  constructor(
    user: CreateUser,
  ) {
    super( {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      url: '/authservice/v1/create-user',
      data: user,
      responseType: 'json',
    }, false );
  }
}

export class VerifyResetPasswordRequest extends BaseRequest<VerifyResetPasswordResponse> {
  constructor(
    token: string,
  ) {
    super( {
      method: 'GET',
      url: `/authservice/v1/verify-reset-password?resetToken=${token}`,
      responseType: 'json',
      loginRequest: false,
    }, false );
  }
}

export class ResetPasswordRequest extends BaseRequest {
  constructor(
    request: ResetPassword,
  ) {
    super( {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      url: '/authservice/v1/reset-password',
      data: request,
      responseType: 'json',
    }, false );
  }
}

export class ForgotPasswordRequest extends BaseRequest {
  constructor(
    email: string,
  ) {
    super( {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      url: '/authservice/v1/forgot-password',
      data: { email: email },
      responseType: 'json',
    }, false );
  }
}

export class LoginSamlRequest extends BaseRequest {
  constructor(
    credentials: SamlCredentials,
  ) {
    super( {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      url: '/authservice/v1/login/saml',
      data: credentials,
      withCredentials: true,
      responseType: 'json',
      loginRequest: true,
    }, false );
  }
}

export class SamlLoginLinkRequest extends BaseRequest<SamlLoginLinkResponse> {
  constructor(
    tenantSlug: string,
  ) {
    super( {
      method: 'GET',
      url: `/authservice/v1/saml-login-link?tenantSlug=${tenantSlug}`,
      responseType: 'json',
    }, false );
  }
}

export interface VerifyResetPasswordResponse {
  email: string;
  resetToken: string;
}

export interface ResetPassword {
  email: string;
  resetToken: string;
  password: string;
}

export interface Credentials {
  username: string;
  password: string;
  newPassword?: string;
}

export interface ChangePassword {
  currentPassword: string;
  newPassword: string;
  confirmPassword: string;
}

export interface TokenResponse {
  /**
   * Access token, contains some info about the user
   */
  accessToken: string;
  /**
   * Permissions for authenticated user
   */
  permissions: string[];
  newPasswordRequired: boolean;
}

export interface SamlCredentials {
  code: string;
  tenantSlug: string;
}

/**
 * User requestData that is collected from id token
 */
export interface IUserInfo {
  email: string;
  tenant_id: number;
  tenant_slug: string;
  tenant_name: string | undefined;
  given_name: string;
  family_name: string;
  role: string;
  tier: string | undefined;
  sub: string;
}

export interface IToken {
  readonly value: string;
  /**
   * Expiration Time in UTC
   */
  readonly exp: dayjs.Dayjs;
  /**
   * Issued At Time in UTC
   */
  readonly iat: dayjs.Dayjs;
  /**
   * User name used to refresh token
   */
  readonly userName: string;
  readonly tenantSlug: string;
}

/**
 * Base auth build based on id token and access token without local account context.
 */
export interface IBaseAuth {
  userInfo: IUserInfo;
  token: IToken;
  userPermissions: string | PermissionType[];
  userChangePasswordRequired: boolean;
  tokenMeta?: JsonObject;
}
// sub: Subject
/**
 * Full auth with account context
 */
export interface IFullAuth extends IBaseAuth {
  accountIndex: number;
  userMeta: IAppUserMeta;
}

export interface ITenantInfo {
  id: number;
  name: string;
  slug: string;
}

export const parseTokenResponse = ( tokenResponse: TokenResponse ): IBaseAuth => {
  const accessToken = tokenResponse.accessToken;
  const parsedToken: object = JSON.parse( Base64.decode( accessToken.split( '.' )[1] ) );
  const userInfo: IUserInfo = {
    email: parsedToken['email'],
    role: parsedToken['custom:role'],
    tier: parsedToken['custom:tier'],
    tenant_id: Number( parsedToken['custom:tenant_id'] ),
    tenant_slug: parsedToken['custom:tenant_slug'],
    tenant_name: parsedToken['custom:tenant_name'],
    given_name: parsedToken['given_name'],
    family_name: parsedToken['family_name'],
    sub: parsedToken['sub'],
  };

  const createdAt: dayjs.Dayjs = dayjs.unix( parsedToken['iat'] as number ).utc();
  const expiredAt: dayjs.Dayjs = dayjs.unix( parsedToken['exp'] as number ).utc();
  const permissions: PermissionType[] = tokenResponse.permissions;
  const newPasswordRequired: boolean = tokenResponse.newPasswordRequired ? tokenResponse.newPasswordRequired : false;

  return {
    token: {
      value: accessToken,
      iat: createdAt,
      exp: expiredAt,
      userName: userInfo.email,
      tenantSlug: userInfo.tenant_slug,
    },
    userInfo: userInfo,
    userPermissions: permissions,
    userChangePasswordRequired: newPasswordRequired,
    tokenMeta: parsedToken as JsonObject,
  };
};

export interface VerifyInviteResponse {
  email: string;
  inviteToken: string;
  samlIdpLoginUrl?: string;
}

export interface CreateUser {
  email: string;
  inviteToken: string;
  password: string;
  firstName: string;
  lastName: string;
  isEulaAccepted: boolean;
}

export interface RoleDetails {
  code: string;
  id: number;
  description: string;
  label: string;
}

export interface IUser {
  id: number;
  code: string;
  email: string;
  firstName: string;
  lastName: string;
  status: string;
  sub: string;
  tenantId: number;
  roles: RoleDetails[];
  createdAt: string;
  updatedAt: string;
}

export interface CustomFieldItem {
  name: string;
  type: 'string' | 'number' | 'boolean' | 'date' | 'email' | 'object';
  maximum?: number;
  minimum: number;
  minLength: number;
  maxLength: number;
  isRequired: boolean;
  label: string;
  options?: Option[];
}

interface Option {
  key: string;
  value: string;
}

export interface CustomSchemaItem {
  id: number;
  code: string;
  productCode: string;
  entity: string;
  fields: {
    items: CustomFieldItem[];
  };
  jsonSchema: JsonValue;
  createdAt: string;
  updatedAt: string;
}

export interface InvitationItem {
  id: number;
  email: string;
  name: string;
  street: string;
  slug: string;
  houseNumber: string;
  zipCode: string;
  city: string;
  country: string;
  parentOrganizationId: number;
  customFields: Object;
  expiresAt: string;
  createdAt: string;
  updatedAt: string;
}

export interface InvitationResponse {
  customSchema: CustomSchemaItem;
  invitation: InvitationItem;
  samlIdpLoginUrl?: string;
}

export interface SamlLoginLinkResponse {
  tenantSlug: string;
  samlIdpLoginUrl: string;
}

export interface CreateOrganization {
  firstName: string;
  lastName: string;
  password: string;
  street: string;
  houseNumber: string;
  zipCode: string;
  city: string;
  country: string;
  isEulaAccepted: boolean;
  customFields: Object;
  invitationToken: string;
}

export interface OrganizationItem {
  id: number;
  code: string;
  parentId: number;
  email: string;
  name: string;
  type: string;
  street: string;
  houseNumber: string;
  zipCode: string;
  city: string;
  country: string;
  parentOrganizationId: number;
  customFields: JsonObject;
  createdAt: string;
  updatedAt: string;
  deletedAt: string;
}

export interface CreateOrganizationResponse {
  organization: OrganizationItem;
  user: IUser;
}

export class VerifyOrgInvitationRequest extends BaseRequest<InvitationResponse> {
  constructor(
    token: string,
  ) {
    super( {
      method: 'GET',
      url: `/authservice/v1/verify-org-invitation?invitationToken=${token}`,
      responseType: 'json',
      loginRequest: false,
    }, false );
  }
}

export class CreateOrganizationRequest extends BaseRequest<CreateOrganizationResponse> {
  constructor(
    payload: CreateOrganization,
  ) {
    super( {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      url: '/authservice/v1/create-org-and-user',
      data: payload,
      responseType: 'json',
    }, false );
  }
}
