import React from 'react';
import { useTranslation } from 'react-i18next';
import { PermissionType } from 'Services/permissions/interfaces';
import { permissionDict as p } from 'Services/permissions/permission-dict';
import { hasRequiredPermissions } from 'Services/permissions';
import { MenuItem } from 'App/menu-items';
import { useAppUser } from './AppUserProvider';

export type ProductIdType = 'eis' | 'pb' | 'pbm';

export const eisPID: ProductIdType = 'eis';
export const pbPID: ProductIdType = 'pb';
export const pbmID: ProductIdType = 'pbm';

/**
 * Interface that describe product
 */
export interface IAppProductDescriptor {
  /**
   * Id that can identify product
   */
  readonly id: ProductIdType;
  /**
   * Name of the product displayed in selector and next to the emil logo in top left corner
   */
  readonly name: string;
  /**
   * This is needed so we know how to limit user permissions based on current product.
   */
  readonly limitPermissions?: PermissionType[];
  menuItems: MenuItem[];
}

/**
 * It provides all products descriptors supported by this application.
 */
export const AppProductsProvider: React.FC = ( props ) => {
  const { t } = useTranslation( [ 'base' ] );
  const products = React.useMemo<IAppProductDescriptor[]>( () => {
    const eis: IAppProductDescriptor = {
      id: eisPID,
      name: 'EMIL Insurance Suite',
      menuItems: [
        {
          id: 'home',
          title: t( 'menu.item.home' ),
          type: 'item',
          icon: 'feather icon-home',
          url: '/dashboard',
          breadcrumbs: false,
          requiredPermissions: [ [ p.eis_policy_view ], [ p.eis_products_view ] ],
        },
        {
          id: 'customers',
          title: t( 'menu.item.partner' ),
          type: 'collapse',
          icon: 'feather icon-users',
          url: '/',
          breadcrumbs: true,
          requiredPermissions: [ p.eis_policy_view ],
          children: [
            {
              id: 'customers',
              title: t( 'menu.item.customers' ),
              type: 'item',
              url: '/customers',
              breadcrumbs: false,
              requiredPermissions: [ p.eis_policy_view ],
            },
          ],
        },
        {
          id: 'policies',
          title: t( 'menu.item.policies' ),
          type: 'collapse',
          icon: 'feather icon-box',
          url: '/',
          breadcrumbs: false,
          requiredPermissions: [ p.eis_policy_view ],
          ids: [ 'policies', 'leads' ],
          children:[
            {
              id: 'leads',
              title: t( 'menu.item.leads' ),
              type: 'item',
              url: '/leads',
              breadcrumbs: false,
              requiredPermissions: [ p.eis_policy_view ],
            },
            {
              id: 'contracts',
              title: t( 'menu.item.contracts' ),
              type: 'item',
              url: '/policies',
              breadcrumbs: false,
              requiredPermissions: [ p.eis_policy_view ],
            },
          ],
        },
        {
          id: 'motor',
          title: t( 'menu.item.motor' ),
          type: 'collapse',
          icon:'feather icon-truck',
          url: '/motor',
          breadcrumbs: true,
          requiredPermissions: [ p.demo_view ],
          children: [
            {
              id: 'fzvInboxList',
              title: t( 'menu.item.gdvList' ),
              type: 'item',
              url: '/motor/gdv',
              breadcrumbs: false,
              nestedApplication: true,
              requiredPermissions: [ p.gdv_view ],
            },
          ],
        },
        {
          id: 'claims',
          title: t( 'menu.item.claims' ),
          type: 'item',
          icon: 'feather icon-flag',
          url: '/claims',
          breadcrumbs: false,
          requiredPermissions: [ p.eis_claims_view ],
        },
        {
          id: 'management',
          title: t( 'menu.item.management' ),
          type: 'collapse',
          icon: 'feather icon-layers',
          url: '/management',
          breadcrumbs: true,
          // This means OR operation
          requiredPermissions: [ [ p.demo_view ], [ p.eis_settings_view ], [ p.eis_products_view ] ],
          children: [
            {
              id: 'm-dashboard',
              title: t( 'menu.item.dashboard' ),
              type: 'item',
              url: '/management/dashboard',
              requiredPermissions: [ p.demo_view ],
            },
            {
              id: 'general-settings',
              title: t( 'menu.item.generalSettings' ),
              type: 'item',
              url: '/management/general-settings',
              breadcrumbs: false,
              requiredPermissions: [ [ p.eis_settings_view ], [ p.tenants_users_view ] ],
            },
            {
              id: 'product-settings',
              title: t( 'menu.item.productSettings' ),
              type: 'item',
              url: '/management/product-settings',
              breadcrumbs: false,
              requiredPermissions: [ p.eis_products_view ],
            },
          ],
        },
        {
          id: 'finance-management',
          title: t( 'menu.item.financeDashboard' ),
          type: 'collapse',
          icon: 'feather icon-book-open',
          url: '/finance-management',
          breadcrumbs: false,
          requiredPermissions: [ p.eis_finance ],
          children: [
            {
              id: 'finance-reporting',
              title: t( 'menu.item.financeReporting' ),
              type: 'item',
              breadcrumbs: false,
              url: '/finance-management/finance-report',
              requiredPermissions: [ p.eis_finance ],
            },
            {
              id: 'finance-invoices',
              title: t( 'menu.item.financeInvoices' ),
              type: 'item',
              breadcrumbs: false,
              url: '/finance-management/finance-invoices',
              requiredPermissions: [ [ p.feature_beta ], [ p.admin ] ],
            },
          ],
        },
        {
          id: 'editor',
          title:  t( 'menu.item.editor' ),
          type: 'collapse',
          icon: 'feather icon-file-plus',
          url: '/editor',
          breadcrumbs: false,
          requiredPermissions: [ p.eis_products_view ],
          children: [
            {
              id: 'docx-editor',
              title: t( 'menu.item.docxEditor' ),
              type: 'item',
              url: '/editor/docx-documents',
              breadcrumbs: false,
              requiredPermissions: [],
            },
            {
              id: 'static-editor',
              title: t( 'menu.item.staticEditor' ),
              type: 'item',
              url: '/editor/static-documents',
              breadcrumbs: false,
              requiredPermissions: [],
            },
            {
              id: 'document-editor',
              title: t( 'menu.item.documentEditor' ),
              type: 'item',
              url: '/editor/documents',
              breadcrumbs: false,
              requiredPermissions: [],
            },
            {
              id: 'email-editor',
              title: t( 'menu.item.emailEditor' ),
              type: 'item',
              url: '/editor/emails',
              breadcrumbs: false,
              requiredPermissions: [],
            },
          ],
        },
      ],
    };
    const pbm: IAppProductDescriptor = {
      id: pbmID,
      name: 'Premium Benchmarking',
      menuItems: [
        {
          id: 'home',
          title: t( 'menu.item.home' ),
          type: 'item',
          icon: 'feather icon-home',
          url: '/premium-benchmarking/home',
          breadcrumbs: false,
          requiredPermissions: [ p.pbm_all ],
        },
        {
          id: 'dashboards',
          title: t( 'menu.item.dashboards' ),
          type: 'item',
          icon: 'feather icon-bar-chart-2',
          url: '/premium-benchmarking/dashboards',
          breadcrumbs: false,
          requiredPermissions: [ p.pbm_dashboard_view ],
        },
        {
          id: 'scenario-analyses',
          title: t( 'menu.item.scenarioAnalyses' ),
          type: 'item',
          icon: 'feather icon-activity',
          url: '/premium-benchmarking/scenario-analyses',
          breadcrumbs: false,
          requiredPermissions: [ p.pbm_sa_view ],
        },
        {
          id: 'tariffcomparisons',
          title: t( 'menu.item.tariffComparisons' ),
          type: 'item',
          icon: 'compare_arrows',
          iconType: 'material',
          url: '/premium-benchmarking/tariffcomparisons',
          breadcrumbs: false,
          requiredPermissions: [ p.pbm_tc_view ],
        },
        {
          id: 'alerts',
          title: t( 'menu.item.alerts' ),
          type: 'item',
          icon: 'feather icon-bell',
          url: '/premium-benchmarking/alerts',
          breadcrumbs: false,
          requiredPermissions: [ p.pbm_alerts_view ],
        },
        {
          id: 'reports',
          title: t( 'menu.item.reports' ),
          type: 'item',
          icon: 'feather icon-flag',
          url: '/premium-benchmarking/reports',
          breadcrumbs: false,
          requiredPermissions: [ p.pbm_reports_view ],
        },
        {
          id: 'settings',
          title: t( 'menu.item.settings' ),
          type: 'item',
          icon: 'feather icon-sliders',
          url: '/premium-benchmarking/general-settings',
          breadcrumbs: false,
          requiredPermissions: [ p.tenants_settings_all ],
        },
      ],
    };
    return [ eis, pbm ];
  }, [ t ] );

  return (
    <AppProductsContext.Provider value={ products }>
      { props.children }
    </AppProductsContext.Provider>
  );
};

export const AppProductsContext: React.Context<IAppProductDescriptor[]> = React.createContext( undefined as any );

export const useAppProducts = (): IAppProductDescriptor[] => {
  return React.useContext( AppProductsContext );
};

/**
 * It provides all products descriptors for current user by filtering all products by user permissions.
 */
export const AppUserProductsProvider: React.FC = ( props ) => {
  const products = useAppProducts();
  const user = useAppUser();
  const userProducts = React.useMemo<IAppProductDescriptor[]>( () => {
    return products.map( ( product ): IAppProductDescriptor => {
      const userMenuItems = product.menuItems.filter( ( menuItem ) => {
        if ( menuItem.children && menuItem.children.length ) {
          const items = menuItem.children.filter( ( childItem ) => {
            return user.hasPermissions( childItem.requiredPermissions );
          } );

          menuItem.children = items;
        }

        return user.hasPermissions( menuItem.requiredPermissions );
      } );

      return {
        ...product,
        menuItems: userMenuItems,
      };
    } );
  }, [ products, user ] );
  return (
    <AppUserProductsContext.Provider value={ userProducts }>
      { props.children }
    </AppUserProductsContext.Provider>
  );
};

export const AppUserProductsContext: React.Context<IAppProductDescriptor[]> = React.createContext( undefined as any );

export const useAppUserProducts = (): IAppProductDescriptor[] => {
  return React.useContext( AppUserProductsContext );
};

/**
 * Provides context of the current product selected. Product is defined in route descriptor.
 */
export const AppCurrentProductContext: React.Context<IAppProductDescriptor> = React.createContext( undefined as any );

export const useAppCurrentProduct = (): IAppProductDescriptor => {
  return React.useContext( AppCurrentProductContext );
};

export interface ISecurityContext {
  hasPermissions( permissions: PermissionType[] ): boolean;
}

export class DefaultSecurityContext implements ISecurityContext {
  private readonly finalPermissions: PermissionType[];
  constructor( userPermissions: PermissionType[] ) {
    // calculate common permissions
    this.finalPermissions = userPermissions;
  }
  hasPermissions( requiredPermissions: PermissionType[] ): boolean {
    return hasRequiredPermissions( this.finalPermissions, requiredPermissions );
  }
}

export const AppSecurityProvider: React.FC = ( props ) => {
  const currentUser = useAppUser();
  const securityContext = React.useMemo<ISecurityContext>( () => {
    return new DefaultSecurityContext( currentUser.userPermissions );
  }, [ currentUser.userPermissions ] );
  return (
    <AppSecurityContext.Provider value={ securityContext }>
      { props.children }
    </AppSecurityContext.Provider>
  );
};

export const AppSecurityContext: React.Context<ISecurityContext> = React.createContext( undefined as any );

/**
 * Return current security context for current user and product
 */
export const useAppSecurity = (): ISecurityContext => {
  return React.useContext( AppSecurityContext );
};
