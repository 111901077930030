import React, { Fragment, ReactNode, MouseEventHandler } from 'react';
import { Dropdown } from 'react-bootstrap';
import { blankLink } from 'config';
import { useTranslation } from 'react-i18next';
import ExplanatoryTour from 'App/components/tour/ExplanatoryTour';
import { Step, Styles } from 'react-joyride';
import { useRouteMatch } from 'react-router-dom';
import { useTourData } from 'App/components/tour/tour-translation-hooks';
import { useAppLinks } from 'App/components/utils/providers/AppLinks';
import DropdownItem, { DropdownItemProps } from 'react-bootstrap/DropdownItem';
import { useAppCurrentProduct } from 'App/components/utils/providers/AppProductProvider';
import { appBuildId } from 'App/utils';
import { AppNavLink } from 'App/components/utils/routing/AppNavLink';

export interface HelpProps {};

export interface StateTour {
  run: boolean;
  steps: Step[];
  styles?: Styles;
};

const stateDefault: StateTour = {
  run: false,
  steps: [],
};

export const NavHelp: React.FC<HelpProps> = ( props ) => {
  const product = useAppCurrentProduct();
  const { path } = useRouteMatch();
  const { t } = useTranslation( [ 'tour', 'base' ] );
  const links = useAppLinks();
  const tourData = useTourData();
  const tourState = React.useMemo<StateTour>( () => {
    const et = tourData.find( ( tour ) => tour.pagePath === path );
    if ( et !== undefined ) {
      return { steps: et.steps, run: et.run };
    } else {
      return stateDefault;
    }
  }, [ tourData, path ] );
  const [ stateTour, setTourState ] = React.useState<StateTour>( tourState );
  const onUpdateState = React.useCallback( ( data: StateTour ) => {
    setTourState( { ...data } );
  }, [] );
  const handleClickStartTour = React.useCallback<MouseEventHandler<DropdownItemProps>>( ( e ) => {
    e.preventDefault();
    setTourState( ( prevState ) => {
      return { ...prevState, run: true };
    } );
  }, [] );
  let showItemTour: ReactNode;
  if ( stateTour.steps.length > 0 ) {
    showItemTour = (
      <DropdownItem href={ blankLink } role="button" id="startTourLink" onClick={ handleClickStartTour } >
        { t( 'base:helpNav.explanatoryTour' ) }
      </DropdownItem>
    );
  }

  const productHelpMenuItems = React.useMemo<ReactNode>( () => {
    switch ( product.id ) {
      case 'eis': {
        return (
          <Fragment>
            <Dropdown.Item href={ blankLink } role="button">
              { t( 'base:helpNav.documentation' ) } <i className="feather icon-external-link ml-1" />
            </Dropdown.Item>
            <AppNavLink
              to={ links.releaseNotesEisLink }
              className="dropdown-item"
              exact={ true }
            >
              { t( 'base:helpNav.releaseNotes' ) } <i className="feather icon-external-link ml-1" />
            </AppNavLink>
            { showItemTour }
            <AppNavLink
              to={ links.contactSupportLink }
              className="dropdown-item"
              exact={ true }
            >
              { t( 'base:helpNav.contactSupport' ) }
              <i className="feather icon-external-link ml-1" />
            </AppNavLink>
          </Fragment>
        );
      }
      case 'pbm': {
        return (
          <Fragment>
            <Dropdown.Item href={ `${ links.pbHandbook }?build-id=${appBuildId}` } target="_blank">
              { t( 'base:helpNav.documentation' ) } <i className="feather icon-external-link ml-1" />
            </Dropdown.Item>
            <Dropdown.Item href={ `${ links.releaseNotesPbmLink }?build-id=${appBuildId}` } target="_blank">
              { t( 'base:helpNav.releaseNotes' ) } <i className="feather icon-external-link ml-1" />
            </Dropdown.Item>
            { showItemTour }
            <AppNavLink
              to={ links.contactSupportLink }
              className="dropdown-item"
              exact={ true }
            >
              { t( 'base:helpNav.contactSupport' ) }
              <i className="feather icon-external-link ml-1" />
            </AppNavLink>
          </Fragment>
        );
      }
    }
  }, [ links, product, showItemTour, t ] );
  return (
    <Fragment>
      <ExplanatoryTour { ...stateTour } onUpdate={ onUpdateState } showStart={ false } />
      <Dropdown className="drp-help">
        <Dropdown.Toggle variant={ 'link' } id="dropdownHelp">
          <i className="material-icons material-icons-outlined align-vertical-middle">help_outline</i>
        </Dropdown.Toggle>
        <Dropdown.Menu alignRight className="profile-notification min-wid-230 emil-styled pb-3" id="helpMenu">
          <Dropdown.Header>{ t( 'base:helpNav.helpCenter' ) }</Dropdown.Header>
          { productHelpMenuItems }
          <Dropdown.Header>{ t( 'base:helpNav.legal' ) }</Dropdown.Header>
          <Dropdown.Item href={ links.impressum } target="_blank">
            { t( 'base:helpNav.impressum' ) }
          </Dropdown.Item>
          <Dropdown.Item href={ links.privacyPolicy } target="_blank">
            { t( 'base:helpNav.privacyPolicy' ) }
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    </Fragment>
  );
};
