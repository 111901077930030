import React from 'react';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';
import { CustomersRequest } from './customers/requests';
import { PoliciesRequest, DocumentsRequest, PaymentsRequest } from './policies/policies';
import { mockedPayments } from './policies/mockData';
import { IApiService, BaseRequest, BaseApiError, IRequestDescriptor, PagingRequest } from './base';
import { SearchRequest } from './api/search/base';
import { UsersRequest, UserRequest } from './api/users/base';
import { RolesRequest } from './api/role/base';
import { mockedUsers, mockedUser } from './api/users/mock-data';
import { mockedRoles } from './api/role/mock-data';
import { mockedBrandSettings } from './api/settings/mock-data';
import { BrandSettingsRequest, BrandItem } from './api/settings/base';
import { setColorMain } from 'App/ui-utils';
import { history } from '../index';
import { TFunction } from 'i18next';
import { useFormTranslation } from 'App/components/utils/forms/hooks';
import { IToken, TokenResponse, RefreshTokenRequest, parseTokenResponse } from './api/auth/base';
import { Logger } from 'loglevel';
import { IAppEnvConfig } from 'config/interfaces';
import { useAppLogger } from './logger';
import { useAppConfig } from 'config/provider';
import { createBackUrl } from 'App/components/utils/providers/AppUserProvider';
import { ApiContext, MockedApiContext, RealApiContext } from './api-context';
import { publicApiUrl } from 'App/utils';

dayjs.extend( utc );

export interface ListItems<T> {
  items: T[];
  nextPageToken: string;
}

export const getQueryParamsForListAPI = (
  { pageToken, pageSize, filter: filterBy, orderBy }: PagingRequest,
  searchValue?: string,
  searchField: string = 'query',
  orderDelimiter: ':' | ' ' = ':',
) => {
  const queryParams = new URLSearchParams();
  queryParams.set( 'pageSize', pageSize.toString() );
  if ( pageToken > 1 ) {
    queryParams.set( 'pageToken', pageToken.toString() );
  }

  if ( filterBy && filterBy.length > 0 ) {
    const filterPars = filterBy
      .map( ( item ) => `${item.id}=${item.value}` )
      .join( '&' );
    queryParams.set( 'filter', filterPars );
  }

  if ( orderBy && orderBy.length > 0 ) {
    const orderPars = orderBy
      .map( ( item ) => `${item.id}${orderDelimiter}${item.desc ? 'DESC' : 'ASC'}` )
      .join( '&' );
    queryParams.set( 'order', orderPars );
  }

  if ( searchValue && searchValue.length > 0 ) {
    queryParams.set( searchField, searchValue );
  }

  return queryParams;
};

// To learn more about axios - https://github.com/axios/axios
export class AxiosApi implements IApiService {
  private api: AxiosInstance;
  private logger: Logger;
  private accessTokenResolvingPromise: Promise<IToken> | false = false;

  public constructor( config: AxiosRequestConfig, appConfig: IAppEnvConfig, logger: Logger ) {
    this.api = axios.create( config );
    this.logger = logger;
  }

  /**
   * This method is main place where access token is refreshed.
   * Access Token is refreshed before request if:
   * - accessTokenRefreshRequired
   *
   * If more request concurenntly are approaching then all this concurrent
   * request are waiting for refresh token to resolve and only after succesful
   * resolving access token the right request is performed.
   */
  private accessTokenResolver(): Promise<IToken> {
    if ( this.accessTokenRefreshRequired() ) {
      if ( !this.accessTokenResolvingPromise ) {
        // create access token resolving and start resolving access token
        this.accessTokenResolvingPromise = new Promise<IToken>( ( resolve, reject ) => {
          if ( this.accessToken.current !== null ) {
            // we can refresh token by using userName from current accessToken
            const userName = this.accessToken.current.userName;
            this.request<TokenResponse>( new RefreshTokenRequest( userName ) )
              .then( ( token ) => parseTokenResponse( token ) )
              .then( ( auth ) => {
                // replace current accessToken and resolve promise so
                // other waiting requests can reuse this (fresh) access token
                this.accessToken.current = auth.token;
                resolve( auth.token );
              } )
              .catch( ( reason ) => {
                reject( reason );
              } )
              .finally( () => {
                // Promise resolved or rejected we can set it to null
                this.accessTokenResolvingPromise = false;
              } );
          } else {
            // reject because user is not logged in and we can't refresh token
            // without userName
            reject( { response: { status: 401 } } );
            this.accessTokenResolvingPromise = false;
          }
        } );
      }
      return this.accessTokenResolvingPromise;
    } else {
      return Promise.resolve( this.accessToken.current as IToken );
    }
  }

  /**
   * Token need to be refreshed if:
   * - 10 seconds left to expiration time of the current token
   * - we don't have a token.
   *
   * In this implementation and architecture it is impossible to refresh token if
   * this access token is null because we need at least userName to refresh token.
   */
  private accessTokenRefreshRequired(): boolean {
    if ( this.accessToken.current !== null ) {
      const now = dayjs().utc();
      const timeToInvalidateToken = this.accessToken.current.exp.diff ( now ) - 10 * 1000;
      // we can reuse existing token only if currect token is valid at least for 10 seconds.
      // if less than 10 seconds we will need to refresh token before calling any api request.
      return timeToInvalidateToken < 0;
    } else {
      return true;
    }
  }

  private isPublicApiUrl( urlApi: string | undefined ): boolean {
    if( urlApi ) {
      const splitUrl = urlApi.split( '/' );
      if( splitUrl.length >= 1 && splitUrl[1] === 'publicapi' ) {
        return true;
      }
    }
    return false;
  }

  public request<T> ( request: BaseRequest<T> ): Promise<T> {
    const location = history.location;
    const requestDescriptor: IRequestDescriptor = { ...request.descriptor };
    const isLoginRequest = requestDescriptor.loginRequest !== undefined ? requestDescriptor.loginRequest : false;

    let preRequestPromise: Promise<IRequestDescriptor> = Promise.resolve( requestDescriptor );
    if ( requestDescriptor.accessTokenRequired ) {
      preRequestPromise = this.accessTokenResolver()
        .then( ( token ) => {
          const tokenValue = this.isPublicApiUrl( requestDescriptor.url ) ? token.tenantSlug : token.value;

          // Modify request and arm it with token from resolver
          requestDescriptor.headers = {
            ...requestDescriptor.headers,
            Authorization: `Bearer ${tokenValue}`,
          };
          return requestDescriptor;
        } );
    }

    const requestPromise = preRequestPromise
      .then( ( requestConfig ) => {
        return this.api.request<T>( requestConfig );
      } );

    const responsePromise = requestPromise
      .then( ( response ) => {
        // Here we don't do anything with response but we can on very top/root level
        return response.data;
      } )
      .catch( ( error ) => {
        if ( error.response ) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          const response: AxiosResponse<T> = error.response;
          if ( response.status === 400 && isLoginRequest ) {
            const apiError = new BaseApiError( response.data, true );
            return Promise.reject( apiError );
          }
          if ( response.status === 401 ) {
            const apiError = new BaseApiError( error, true );

            if ( location.pathname === '/login/saml' ) {
              return Promise.reject( apiError );
            }

            if ( location.pathname === '/forms' ) {
              return Promise.reject( apiError );
            }

            const fullpath = location.pathname + location.search + location.hash;
            const backUrl = createBackUrl( fullpath );
            if ( backUrl !== '/login' ) {
              const loginUrl = `/login?backUrl=${backUrl}`;
              history.push( loginUrl ); //we will redirect user into login page
            }
            return Promise.reject( apiError );
          } else if ( response.status === 403 ) {
            const apiError = new BaseApiError( error, true );
            //TODO: Backend should send a proper message for expired token
            if ( location.pathname === '/registration' || location.pathname === '/account/resetpassword' ) {
              history.push( '/expired-token' );
            } else {
              history.push( '/noaccess' ); //we will redirect user into noaccess error page
            }
            return Promise.reject( apiError );
          }
        } else if ( error.request ) {
          // The request was made but no response was received
          // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
          const apiError = new BaseApiError( error, false );
          return Promise.reject( apiError );
        } else {
          // Something happened in setting up the request that triggered an Error
          const apiError = new BaseApiError( error, false );
          return Promise.reject( apiError );
        }

        const apiError = new BaseApiError( error, false );
        this.logger.error( 'Api layer didn\'t handle error', apiError );
        return Promise.reject( apiError );
      } );

    return responsePromise;
  }

  accessToken: React.MutableRefObject<IToken | null> = { current: null };
}

export class LocalStorageApi implements IApiService {
  accessToken: React.MutableRefObject<IToken | null> = { current: null };
  private storage: Storage;
  readonly t: TFunction;

  public constructor( t: TFunction ) {
    this.storage = localStorage;
    this.t = t;
    this.initMockedData();
  }

  private initMockedData(): void {
    const customersRequest = new CustomersRequest();
    const customerKey = this.getStorageKey( customersRequest.descriptor );
    this.storage.removeItem( customerKey );

    const policiesRequest = new PoliciesRequest();
    const policyKey = this.getStorageKey( policiesRequest.descriptor );
    this.storage.removeItem( policyKey );

    const documentsRequest = new DocumentsRequest();
    const documentKey = this.getStorageKey( documentsRequest.descriptor );
    this.storage.removeItem( documentKey );

    const paymentsRequest = new PaymentsRequest();
    const paymentKey = this.getStorageKey( paymentsRequest.descriptor );
    this.storage.removeItem( paymentKey );

    // Here we store static search results. Results should be dynamic based on searchText
    // but it is problematic to store every possible url combination in localStarage
    const searchRequest = new SearchRequest( 'mock' );
    const searchKey = this.getStorageKey( searchRequest.descriptor );
    this.storage.removeItem( searchKey );

    const usersRequest = new UsersRequest( '', '' );
    const userRequest = new UserRequest( 1 );
    const usersKey = this.getStorageKey( usersRequest.descriptor );
    const userKey = this.getStorageKey( userRequest.descriptor );

    const rolesRequest = new RolesRequest( );
    const rolesKey = this.getStorageKey( rolesRequest.descriptor );

    const brandingRequest = new BrandSettingsRequest();
    const brandingKey = this.getStorageKey( brandingRequest.descriptor );

    this.storage.removeItem( userKey );
    this.storage.removeItem( rolesKey );
    // this.storage.removeItem ( generalKey );
    // this.storage.removeItem ( brandingKey );
    // this.storage.removeItem ( ticketsKey );

    const users: string = JSON.stringify( mockedUsers );

    const user: string = JSON.stringify( mockedUser );
    const roles: string = JSON.stringify( mockedRoles );

    const payments: string = JSON.stringify( mockedPayments );
    const branding: string = JSON.stringify( mockedBrandSettings );

    this.storage.setItem( usersKey, users );
    this.storage.setItem( userKey, user );
    this.storage.setItem( rolesKey, roles );
    this.storage.setItem( paymentKey, payments );

    const brandingStore = this.storage.getItem( brandingKey );
    if ( brandingStore === null ) {
      this.storage.setItem( brandingKey, branding );
    } else {
      const brandingItem: BrandItem = JSON.parse( brandingStore );
      setColorMain( brandingItem.main_color );
      // setLogoMain( brandingItem.logo );
    }

    // If you want customers in local storage You need to add them here with all customer request response
    // The same with Policies
  }

  public request<T> ( request: BaseRequest ): Promise<T> {
    // Here we create based on request descriptor key for localStorage and we are fetching it.
    // It can be improved later for query params, headers etc...
    const key = this.getStorageKey( request.descriptor );
    const jsonString: string | null = this.storage.getItem( key );
    if ( jsonString === null ) {
      const apiError = new BaseApiError( `No such key in localStorage: "${key}"` );
      return Promise.reject( apiError );
    }

    const data = JSON.parse( jsonString ) as T;
    return Promise.resolve( data );
  }

  private getStorageKey( desc: IRequestDescriptor ): string {
    return desc.method + ': ' + desc.url;
  }
}
// we need to insert mocked data to local storage

// Implementation of API for Emil Admin V2
export class AdminV2Api extends AxiosApi {
  constructor( config: IAppEnvConfig, logger: Logger ) {
    super( {
      baseURL: publicApiUrl,
      // other settings related to emil backend. See Request config docs here: https://github.com/axios/axios
    }, config, logger );
  }
}

export const ApiProvider: React.FC = ( props ) => {
  const config = useAppConfig();
  const logger = useAppLogger();
  const api: IApiService = new AdminV2Api( config, logger );

  return (
    <ApiContext.Provider value={ api }>
      <RealApiContext.Provider value={ api }>
        { props.children }
      </RealApiContext.Provider>
    </ApiContext.Provider>
  );
};

export const MockedApiProvider: React.FC = ( props ) => {
  const t = useFormTranslation();
  const api: IApiService = new LocalStorageApi( t );

  return (
    <ApiContext.Provider value={ api }>
      <MockedApiContext.Provider value={ api }>
        { props.children }
      </MockedApiContext.Provider>
    </ApiContext.Provider>
  );
};
