import React from 'react';
import { IApiService, ApiResponse, PagingRequest } from './base';
import {
  ListProductsRequest,
  GetProductByIdRequest,
  GetProductFactorsRequest,
  GetProductPremiumFormulasRequest,
  GetProductFieldsRequest,
  CreateFactorsRequest,
  CreateProductRequest,
  ValidateFactorsRequest,
  CreateVariableRequest,
  UpdateVariableRequest,
  DeleteVariableRequest,
  CreatePremiumFormulaRequest,
  UpdatePremiumFormulaRequest,
  DeletePremiumFormulaRequest,
  SwapPremiumFormulasOrderRequest,
  UpdateVersionRequest,
  GetProductFactorsTestRequest,
  GetProductFieldsTestRequest,
  ListInsuranceTypesRequest,
  GetDefaultProductFieldsRequest,
  GetCustomStylesSettingsRequest,
  SetCustomStylesSettingsRequest,
  CreateInsuredObjectRequest,
  DeleteInsuredObjectRequest,
  UpdateInsuredObjectRequest,
  DeleteProductRequest,
  GetProductFieldTypesRequest,
  GetBookingFunnelSettingsRequest,
  CreateProductLanguage,
  GetProductLanguages,
  UpdateProductLanguage,
  DownloadTranslationsDocument, UploadTranslationsDocument,
  GetProductNamedRangesRequest,
  CreateProductNamedRangeRequest,
  UpdateProductNamedRangeRequest,
  DeleteProductNamedRangeRequest,
  GetInsuredObjectRequest,
  ListBookingFunnelsRequest,
  CreateBookingFunnelRequest,
  PatchBookingFunnelRequest,
  DeleteBookingFunnelRequest,
  GetBookingFunnelVersionRequest,
  UpdateBookingFunnelRequest,
  SwapProductFieldsOrderRequest,
} from './products/requests';
import {
  ListProducts,
  IProductItem,
  IProductField,
  IProductPremiumFormula,
  ICreateProductPremiumFormula,
  IUpdateVersion,
  IVersionProductItem,
  IInsuranceTypeItem,
  CustomSettingsRequest,
  CustomSettingsResponse,
  CustomSettings,
  ICreateProductResult,
  IProductCreateData,
  IInsuredObjectItem,
  ICreateInsuredObject,
  IUpdateInsuredObject,
  IProductVariable,
  IProductFieldType,
  BookingFunnelSettings,
  CreateProductLanguagePayload,
  UpdateProductLanguagePayload,
  DownloadTranslationsDocumentPayload,
  UploadTranslationsDocumentPayload,
  NamedRange,
  CreateNamedRangeRequest,
  CreateNamedRangeResponse,
  UpdateNamedRangeRequest,
  UpdateNamedRangeResponse,
  BookingFunnelItem,
  IPatchBookingFunnel,
  ICreateBookingFunnel,
  BookingFunnelVersionItem,
  IUpdateBookingFunnel,
} from './products/interfaces';
import { IApiFactorItem, ICFFactorType,
  IFactorItem,
} from 'App/components/management/products/schema-editor/services';
import dayjs from 'dayjs';
import { GetInsuredObjectsRequest } from './products/requests';
import { InsuredObjectTypes, ProductFactorValuesEnum, DeviceTypes } from './products/enums';
import { ProductLanguage } from '../App/components/management/products/shared/types';
import { IApiListResponse } from './policies/new/service';

export interface IProductService {
  getProducts( paging: PagingRequest, searchValue?: string ): Promise<ListProducts>;
  getProductById( productId: number ): Promise<IProductItem>;
  getProductFactors(
    versionId: number, values: ProductFactorValuesEnum, reload?: boolean, isValidation?: boolean,
  ): Promise<IFactorItem[]>;
  getProductFields( versionId: number, insuredObjectIds?: number[] ): Promise<IProductVariable[]>;
  swapProductFieldsOrder( productFieldIdOne: number, productFieldIdTwo: number ): Promise<ApiResponse>;
  getProductPremiumFormulas( versionId: number ): Promise<IProductPremiumFormula[]>;
  createProduct( productData: IProductCreateData ): Promise<ICreateProductResult>;
  deleteProduct( productId: number ): Promise<ApiResponse>;
  createFactors( factorsData: FormData ): Promise<ICFFactorType[]>;
  validateFactors( factorsData: FormData ): Promise<ICFFactorType[]>;
  addVariable( variable: IProductField ): Promise<IProductField>;
  addPremiumFormula( premiumFormula: ICreateProductPremiumFormula ): Promise<IProductPremiumFormula>;
  updateVariable( variableId: number, variable: IProductField ): Promise<IProductField>;
  updatePremiumFormula(
    premiumFormulaId: number,
    premiumFormula: ICreateProductPremiumFormula
  ): Promise<IProductPremiumFormula>;
  deleteVariable( variableId: number ): Promise<ApiResponse>;
  deletePremiumFormula( pFormulaId: number ): Promise<ApiResponse>;
  swapPremiumFormulasOrder( id1: number, id2: number ): Promise<ApiResponse>;
  updateVersion( version: IUpdateVersion ): Promise<IVersionProductItem>;
  getAllInsuranceTypes(): Promise<IInsuranceTypeItem[]>;
  getDefaultProductFields( objectTypeId: number ): Promise<IProductField[]>;
  getBookingFunnelSettingsByKey( settingKey: string ): Promise<BookingFunnelSettings | undefined>;
  createBookingFunnel( bookingFunnel: ICreateBookingFunnel ): Promise<BookingFunnelItem>;
  patchBookingFunnel( bookingFunnel: IPatchBookingFunnel ): Promise<BookingFunnelItem>;
  updateBookingFunnel( bookingFunnel: IUpdateBookingFunnel ): Promise<BookingFunnelItem>;
  listBookingFunnels( paging: PagingRequest ): Promise<IApiListResponse<BookingFunnelItem>>;
  deleteBookingFunnel( bookingFunnelCode: string ): Promise<ApiResponse>;
  getBookingFunnelVersion( bookingFunnelCode: string, version: number ): Promise<BookingFunnelItem>;
  setCustomStylesSettings( settings: CustomSettingsRequest ): Promise<CustomSettingsResponse>;
  getCustomStylesSettings( productCode: string ): Promise<CustomSettings>;
  getInsuredObjects( productVersionId: number, expand?: string[] ): Promise<IInsuredObjectItem[]>;
  getInsuredObject( insuredObjectId: number, expand?: string[] ): Promise<IInsuredObjectItem>;
  createInsuredObject( objectData: ICreateInsuredObject ): Promise<IInsuredObjectItem>;
  updateInsuredObject( dataObject: IUpdateInsuredObject ): Promise<IInsuredObjectItem>;
  deleteInsuredObject( insuredObjectId: number ): Promise<ApiResponse>;
  getProductFieldTypes( ): Promise<IProductFieldType[]>;
  createProductLanguage( payload: CreateProductLanguagePayload ): Promise<ProductLanguage>;
  getProductLanguages( paging: PagingRequest ): Promise<IApiListResponse<ProductLanguage>>;
  updateProductLanguage( payload: UpdateProductLanguagePayload ): Promise<ProductLanguage>;
  downloadTranslationsDocument( payload: DownloadTranslationsDocumentPayload ): Promise<Blob>;
  uploadTranslationsDocument( payload: UploadTranslationsDocumentPayload ): Promise<any>;
  getProductNamedRanges( versionId: number ): Promise<NamedRange[]>;
  createProductNamedRange( newNamedRange: CreateNamedRangeRequest ): Promise<NamedRange>;
  updateProductNamedRange( updatedNamedRange: UpdateNamedRangeRequest ): Promise<NamedRange>;
  deleteProductNamedRange( namedRangeId: number ): Promise<ApiResponse>;
}

const invalidKey: string = 'invalid';

const mapApiFactorItemToFactorItem = ( input: IApiFactorItem ): IFactorItem => {
  return {
    ...input,
    createdAt: dayjs( input.createdAt ),
    updatedAt: dayjs( input.updatedAt ),
  };
};

export class ProductService implements IProductService {
  protected api: IApiService;
  protected factors: Record<number, IApiFactorItem[] | undefined>;
  protected fields: Record<number, IProductVariable[] | undefined>;

  constructor( api: IApiService ) {
    this.api = api;
    this.factors = {};
    this.fields = {};
  }

  getProducts( paging: PagingRequest, searchValue?: string ): Promise<ListProducts> {
    return this.api.request( new ListProductsRequest( paging, searchValue ) )
      .then( ( response ) => {
        const { items, nextPageToken } = response;
        const res: ListProducts = {
          products: items,
          nextPageToken: items ? items.length < paging.pageSize ? '1' : nextPageToken : nextPageToken,
        };
        return res;
      } );
  }

  getProductById( productId: number ): Promise<IProductItem> {
    return this.api.request( new GetProductByIdRequest( productId ) )
      .then( ( response ) => {
        const { product } = response;
        if ( product === undefined ) {
          throw new Error( `Can't load productId: ${productId}` );
        }
        return product;
      } );
  }

  getProductFactors(
    versionId: number,
    values: ProductFactorValuesEnum,
    reload?: boolean,
    isValidation?: boolean,
  ): Promise<IFactorItem[]> {
    // check if we have valid version of factors in local cache for versionId
    // we keep in cache only expanded requests. If expand is false we return also full version from cache if valid.
    // reload flag can be used to fetch all factors
    return this.checkFactors(
      versionId,
      values,
      reload ? true : false,
      isValidation === undefined ? true : isValidation )
      .then( ( items ) => {
        return items.map( mapApiFactorItemToFactorItem );
      } )
      .catch( () => {
        throw new Error( `Can't load product factors for productVersionId: ${versionId}` );
      } );
  }

  private checkFactors(
    versionId: number,
    values: ProductFactorValuesEnum,
    reload: boolean,
    isValidation: boolean,
  ): Promise<IApiFactorItem[]> {
    // get version from cache then fire simple check request if version in cache is valid based on updatedAt time.
    const productFactors = this.factors[versionId];
    if ( productFactors !== undefined && productFactors.length > 0 ) {
      if ( !isValidation ) {
        return Promise.resolve( productFactors );
      }
      // productFactors from cache. We need to compare to updatedAt time with simple lightweight test request
      return this.getCacheValidationKeyForFactors( versionId ).then( ( updatedAt ) => {
        if ( productFactors[0].updatedAt === updatedAt && !reload ) {
          // factors in cache are valid and we can use it
          return productFactors;
        } else {
          // we need to fetch all factors and store it
          return this.getAllFactors( versionId, values ).then( ( items ) => {
            // store it in storage for future usage
            this.saveFactorsToCache( versionId, items, values );
            return items;
          } );
        }
      } );
    } else {
      // we don't have factors for this productId in cache
      return this.getAllFactors( versionId, values ).then( ( items ) => {
        // store it in storage for future usage
        this.saveFactorsToCache( versionId, items, values );
        return items;
      } );
    }
  }

  private saveFactorsToCache( versionId: number, factors: IApiFactorItem[], values: ProductFactorValuesEnum ) {
    // Factors with short value lists do not take the long time to fetch, so they are not cached
    if ( values === ProductFactorValuesEnum.All ) {
      this.factors[versionId] = factors;
    }
  }

  private getCacheValidationKeyForFactors( versionId: number ): Promise<string> {
    return this.api.request( new GetProductFactorsTestRequest( versionId ) )
      .then( ( response ) => {
        const { items } = response;
        if ( items === undefined || items.length === 0 ) {
          return invalidKey;
        }
        return items[0].updatedAt; // the key is simply string representing updatedAt date
      } )
      .catch( () => {
        return invalidKey;
      } );
  }

  private getAllFactors(
    versionId: number,
    values: ProductFactorValuesEnum,
  ): Promise<IApiFactorItem[]> {
    return this.api.request( new GetProductFactorsRequest( versionId, values ) )
      .then( ( response ) => {
        const { items } = response;
        if ( items === undefined ) {
          return [];
        }
        return items;
      } );
  }

  getProductFields( versionId: number, insuredObjectIds?: number[] ): Promise<IProductVariable[]> {
    return this.checkFields( versionId, insuredObjectIds )
      .catch( () => {
        throw new Error( `Can't load product fields for productVersionId: ${versionId}` );
      } );
  }

  async swapProductFieldsOrder( productFieldIdOne: number, productFieldIdTwo: number ): Promise<ApiResponse> {
    const response = await this.api.request(
      new SwapProductFieldsOrderRequest( productFieldIdOne, productFieldIdTwo ),
    );

    return response;
  }

  private checkFields( versionId: number, insuredObjectIds?: number[] ): Promise<IProductVariable[]> {
    // get version from cache then fire simple check request if version in cache is valid based on updatedAt time.
    const productFields = this.fields[versionId];
    if ( productFields !== undefined && productFields.length > 0 ) {
      // productFields from cache. We need to compare to updatedAt time with simple lightweight test request
      return this.getCacheValidationKeyForFields( versionId ).then( ( updatedAt ) => {
        if ( productFields[0].updatedAt === updatedAt ) {
          // factors in cache are valid and we can use it
          return productFields;
        } else {
          // we need to fetch all factors and store it
          return this.getAllFields( insuredObjectIds ).then( ( items ) => {
            // store it in storage for future usage
            this.fields[versionId] = items;
            return items;
          } );
        }
      } );
    } else {
      // we don't have factors for this productId in cache
      return this.getAllFields( insuredObjectIds ).then( ( items ) => {
        this.fields[versionId] = items;
        return items;
      } );
    }
  }

  private getCacheValidationKeyForFields( versionId: number ): Promise<string> {
    return this.api.request( new GetProductFieldsTestRequest( versionId ) )
      .then( ( response ) => {
        const { items } = response;
        if ( items === undefined || items.length === 0 ) {
          return invalidKey;
        }
        // this is not working for fields because we can add/remove fields from/to second...last index
        // and first field can be not touched at all...
        return invalidKey; // this is for now because there is no valid and good way to
        // check if data in cache is valid or not. Backend fix is required for product version updatedAt property.
        // TODO: change it later for checking if product updatedAt property changed.
        // return items[0].updatedAt; // the key is simply string representing updatedAt date
      } )
      .catch( () => {
        return invalidKey;
      } );
  }

  private getAllFields( insuredObjectIds?: number[] ): Promise<IProductVariable[]> {
    const initialPageToken: string = '0';
    return this.api.request( new GetProductFieldsRequest( initialPageToken, insuredObjectIds ) )
      .then( ( response ) => {
        const { items, nextPageToken } = response;
        const fields: IProductVariable[] = items;
        //TODO: Backend only supports pageSize till 100, so to fetch more than 100 fields have to check next page token
        if ( nextPageToken.length ) {
          return this.api.request( new GetProductFieldsRequest( nextPageToken, insuredObjectIds ) )
            .then( ( res ) => {
              const updatedFields = [ ...fields, ...res.items ];

              return updatedFields;
            } );
        }
        if ( fields === undefined ) {
          return [];
        }
        return fields;
      } );
  }

  getProductPremiumFormulas( versionId: number ): Promise<IProductPremiumFormula[]> {
    return this.api.request( new GetProductPremiumFormulasRequest( versionId ) )
      .then( ( response ) => {
        const { premiumFormulas } = response;
        if ( premiumFormulas === undefined ) {
          return [];
        }
        return premiumFormulas;
      } )
      .catch( ( e ) => {
        throw new Error( `Can't load premium formulas for productVersionId: ${versionId}` );
      } );
  }

  async createProduct( productData: IProductCreateData ): Promise<ICreateProductResult> {
    const response = await this.api.request( new CreateProductRequest( productData ) );
    const { product } = response;

    return product;
  }

  async deleteProduct( productId: number ): Promise<ApiResponse> {
    const response = await this.api.request( new DeleteProductRequest( productId ) );

    return response;
  }

  async createFactors( factorsData: FormData ): Promise<ICFFactorType[]> {
    const response = await this.api.request( new CreateFactorsRequest( factorsData ) );
    const { productFactors } = response;

    return productFactors;
  }

  async validateFactors( factorsData: FormData ): Promise<ICFFactorType[]> {
    const response = await this.api.request( new ValidateFactorsRequest( factorsData ) );
    const { productFactors } = response;

    return productFactors;
  }

  async addVariable( variable: IProductField ): Promise<IProductField> {
    const response = await this.api.request( new CreateVariableRequest( variable ) );
    const { productField } = response;

    return productField;
  }

  async addPremiumFormula( pFormula: ICreateProductPremiumFormula ): Promise<IProductPremiumFormula> {
    const response = await this.api.request( new CreatePremiumFormulaRequest( pFormula ) );
    const { premiumFormula } = response;

    return premiumFormula;
  }

  async updateVariable( variableId: number, variable: IProductField ): Promise<IProductField> {
    const response = await this.api.request( new UpdateVariableRequest( variableId, variable ) );
    const { productField } = response;

    return productField;
  }

  async updatePremiumFormula(
    premiumFormulaId: number, pFormula: ICreateProductPremiumFormula,
  ): Promise<IProductPremiumFormula> {
    const response = await this.api.request( new UpdatePremiumFormulaRequest( premiumFormulaId, pFormula ) );
    const { premiumFormula } = response;

    return premiumFormula;
  }

  async deleteVariable( variableId: number ): Promise<ApiResponse> {
    const response = await this.api.request( new DeleteVariableRequest( variableId ) );

    return response;
  }

  async deletePremiumFormula( pFormulaId: number ): Promise<ApiResponse> {
    const response = await this.api.request( new DeletePremiumFormulaRequest( pFormulaId ) );

    return response;
  }

  async swapPremiumFormulasOrder( id1: number, id2: number ): Promise<ApiResponse> {
    const response = await this.api.request( new SwapPremiumFormulasOrderRequest( id1, id2 ) );

    return response;
  }

  async updateVersion( version: IUpdateVersion ): Promise<IVersionProductItem> {
    const response = await this.api.request( new UpdateVersionRequest( version ) );
    const { productVersion } = response;

    return productVersion;
  }

  async getAllInsuranceTypes(): Promise<IInsuranceTypeItem[]> {
    const response = await this.api.request( new ListInsuranceTypesRequest( ) );
    const { items } = response;

    return items;
  }

  async getDefaultProductFields( objectTypeId: number ): Promise<IProductField[]> {
    const response = await this.api.request( new GetDefaultProductFieldsRequest( objectTypeId ) );
    const { items } = response;

    return items;
  }

  async getBookingFunnelSettingsByKey( settingKey: string ): Promise<BookingFunnelSettings | undefined> {
    const response = await this.api.request( new GetBookingFunnelSettingsRequest( settingKey ) );
    const { values } = response;

    return values[0] || undefined;
  }

  async createBookingFunnel( bookingFunnel: ICreateBookingFunnel ): Promise<BookingFunnelItem> {
    const { bookingFunnel: createdBookingFunnel } =
      await this.api.request( new CreateBookingFunnelRequest( bookingFunnel ) );

    return createdBookingFunnel;
  }

  async patchBookingFunnel( bookingFunnel: IPatchBookingFunnel ): Promise<BookingFunnelItem> {
    const { bookingFunnel: patchedBookingFunnel } =
      await this.api.request( new PatchBookingFunnelRequest( bookingFunnel ) );

    return patchedBookingFunnel;
  }

  async updateBookingFunnel( bookingFunnel: IUpdateBookingFunnel ): Promise<BookingFunnelItem> {
    const { bookingFunnel: updatedBookingFunnel } =
      await this.api.request( new UpdateBookingFunnelRequest( bookingFunnel ) );

    return updatedBookingFunnel;
  }

  async listBookingFunnels( paging: PagingRequest ): Promise<IApiListResponse<BookingFunnelItem>> {
    const bookingFunnels = await this.api.request( new ListBookingFunnelsRequest( paging ) );

    return bookingFunnels;
  }

  async deleteBookingFunnel( bookingFunnelCode: string ): Promise<ApiResponse> {
    const response = await this.api.request( new DeleteBookingFunnelRequest( bookingFunnelCode ) );

    return response;
  }

  async getBookingFunnelVersion( bookingFunnelCode: string, version: number ): Promise<BookingFunnelVersionItem>{
    const response =
      await this.api.request( new GetBookingFunnelVersionRequest( { code: bookingFunnelCode, version } ) );
    const { bookingFunnelVersion } = response;

    return bookingFunnelVersion;
  };


  async setCustomStylesSettings( settings: CustomSettingsRequest ): Promise<CustomSettingsResponse> {
    const response = await this.api.request( new SetCustomStylesSettingsRequest( settings ) );

    return response;
  }

  async getCustomStylesSettings( productCode: string ): Promise<CustomSettings> {
    const response = await this.api.request( new GetCustomStylesSettingsRequest( productCode ) );
    const { values } = response;

    return values;
  }

  async getInsuredObjects(
    productVersionId: number, expand?: string[],
  ): Promise<IInsuredObjectItem[]> {
    const response = await this.api.request(
      new GetInsuredObjectsRequest( productVersionId, expand ),
    );

    const { items } = response;
    const insuredObjects = this.sortInsuredObjects( items );

    return insuredObjects && insuredObjects.length ? insuredObjects : [];
  }

  async getInsuredObject(
    insuredObjectId: number, expand?: string[],
  ): Promise<IInsuredObjectItem> {
    const response = await this.api.request(
      new GetInsuredObjectRequest( insuredObjectId, expand ),
    );

    return response.insuredObject;
  }

  async createInsuredObject( objectData: ICreateInsuredObject ): Promise<IInsuredObjectItem> {
    const response = await this.api.request( new CreateInsuredObjectRequest( objectData ) );
    const { insuredObject } = response;

    return insuredObject;
  }

  async updateInsuredObject( dataObject: IUpdateInsuredObject ): Promise<IInsuredObjectItem> {
    const response = await this.api.request( new UpdateInsuredObjectRequest( dataObject ) );
    const { insuredObject } = response;

    return insuredObject;
  }

  async deleteInsuredObject( insuredObjectId: number ): Promise<ApiResponse> {
    const response = await this.api.request( new DeleteInsuredObjectRequest( insuredObjectId ) );

    return response;
  }

  async getProductFieldTypes( ): Promise<IProductFieldType[]> {
    const response = await this.api.request( new GetProductFieldTypesRequest( ) );
    const { items } = response;

    return items && items.length ? items : [];
  }

  async createProductLanguage( payload: CreateProductLanguagePayload ): Promise<ProductLanguage> {
    const response = await this.api.request( new CreateProductLanguage( payload ) );

    return response;
  }

  async getProductLanguages( paging: PagingRequest ): Promise<IApiListResponse<ProductLanguage>> {
    const response = await this.api.request( new GetProductLanguages( paging ) );

    return response;
  }

  async updateProductLanguage( payload: UpdateProductLanguagePayload ): Promise<ProductLanguage> {
    const response = await this.api.request( new UpdateProductLanguage( payload ) );

    return response;
  }

  async downloadTranslationsDocument( payload: DownloadTranslationsDocumentPayload ): Promise<Blob> {
    const response = await this.api.request( new DownloadTranslationsDocument( payload ) );

    return response;
  }

  async uploadTranslationsDocument( payload: UploadTranslationsDocumentPayload ): Promise<any> {
    const response = await this.api.request( new UploadTranslationsDocument( payload ) );

    return response;
  }

  private sortInsuredObjects( insuredObjects: IInsuredObjectItem[] ): IInsuredObjectItem[] {
    const result: IInsuredObjectItem[] = [];
    const mobileType = DeviceTypes.MobileDevice;
    const strMobileLength = mobileType.length;
    const diviceType = DeviceTypes.Device;
    const strDeviceLength = diviceType.length;
    const foundDefauldGroup = insuredObjects.find(
      ( insuredItem ) => insuredItem.name.toLowerCase() === InsuredObjectTypes.Default,
    );

    if ( foundDefauldGroup ) {
      result.push( foundDefauldGroup );
    }

    const filterGroup = insuredObjects.filter( ( item ) => item.name !== InsuredObjectTypes.Default );

    filterGroup.sort( ( a, b ) => a.name.localeCompare( b.name ) ).map( ( insured ) => {
      if ( insured.productFields ) {
        const typeMobileInsured = insured.name.substring( 0, strMobileLength );
        const typeDeviceInsured = insured.name.substring( 0, strDeviceLength );

        if ( typeMobileInsured === mobileType || typeDeviceInsured === diviceType ) {
          insured.productFields.sort( ( a, b ) => a.label.localeCompare( b.label ) );
        }
      }

      return insured;
    } );

    result.push( ...filterGroup );

    return result;
  }

  async getProductNamedRanges( versionId: number ): Promise<NamedRange[]> {
    return this.api.request( new GetProductNamedRangesRequest( versionId ) )
      .then( ( response ) => {
        const { items: namedRanges } = response;
        if ( namedRanges === undefined ) {
          return [];
        }
        return namedRanges.sort( ( range1, range2 ) => range1.name.localeCompare( range2.name ) );
      } )
      .catch( ( e ) => {
        throw new Error( `Can't load premium formulas for productVersionId: ${versionId}` );
      } );
  }

  async createProductNamedRange( newNamedRange: CreateNamedRangeRequest ): Promise<NamedRange> {
    const response = await this.api.request<CreateNamedRangeResponse>(
      new CreateProductNamedRangeRequest( newNamedRange ) );
    const { namedRange } = response;

    return namedRange;
  }

  async updateProductNamedRange( updatedNamedRange: UpdateNamedRangeRequest ): Promise<NamedRange> {
    const response = await this.api.request<UpdateNamedRangeResponse>(
      new UpdateProductNamedRangeRequest( updatedNamedRange.id, updatedNamedRange ) );
    const { namedRange } = response;

    return namedRange;
  }

  async deleteProductNamedRange( namedRangeId: number ): Promise<ApiResponse> {
    const response = await this.api.request<ApiResponse>( new DeleteProductNamedRangeRequest( namedRangeId ) );

    return response;
  }
};

export const ProductServiceContext: React.Context<IProductService> = React.createContext( undefined as any );

export const useProductsService = (): IProductService => {
  return React.useContext( ProductServiceContext );
};
